import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";
import remove from "lodash/remove";

export default {
  data() {
    return {
      loadingDownload: false,
      loadingDownloadLock: false,
      loadingOffers: [],
    };
  },
  computed: {
    ...mapFields("offer", {
      offerIds: "allIds",
      offerIdsCached: "cacheAllIds",
      offerHandled: 'handled',
      offerActionHandled: 'handleAction'
    }),
    ...mapGetters("offer", {
      offerItems: 'list',
      findOffer: "find",
    }),
  },
  methods: {
    ...mapActions("offer", {
      retrieveOffer: "load",
      fetchAllOffers: 'fetchAll',
      updateOffer: "update",
      offerActions: "action",
    }),
    resolveOffer(itemId) {
      if (!this.offerIdsCached.includes(itemId) && !this.loadingOffers.includes(itemId)) {
        this.loadingOffers.push(itemId);
        this.retrieveOffer(decodeURIComponent(itemId));
      }
      return this.findOffer(itemId);
    },
    resolveOffers(itemIds) {
      if (typeof itemIds === "undefined") {
        return [];
      }
      return itemIds.map((itemId) => {
        return this.resolveOffer(itemId);
      }).filter(e => e);
    },
    getOfferItem(offerIri, type) {
      if (offerIri !== null) {
        let offer = this.resolveOffer(offerIri);
        if (offer) {
          return offer[type];
        }
      }
      return '';
    },
    removeOfferRelated(item, type, relatedIri) {
      item[type] = remove(item[type], function (n) {
        return !(n === relatedIri);
      });
    },
    generateOfferDocument(offer) {
      this.offerActions({item: offer, action: 'generate'});
    },
    generateOfferConfirmDocument(offer) {
      this.offerActions({item: offer, action: 'confirm'});
    },
    declineOffer(offer) {
      this.offerActions({item: offer, action: 'decline'});
    },
    async freezeDocument(offer) {
      let item = {
        ["@id"]: offer["@id"],
        frozen: true
      };
      return this.updateOffer(item);
    },
  }
};
