<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <v-text-field
          :label="$t('offerNumber')"
          type="text"
          v-model="item.number"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          :label="$t('customerNumber')"
          type="text"
          v-model="item.customer.customerNumber"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-select
          :label="$t('offerFilterConfirmed')"
          :items="confirmedOptions"
          item-text="name"
          item-value="key"
          v-model="item.confirmed"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'OfferFilter',
  props: {
    values: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      confirmedOptions: [
        {name: this.$t("offerConfirmedFilterItem"), key: true},
        {name: this.$t("offerDeclinedFilterItem"), key: false},
      ],
    };
  },
  computed: {
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    }
  },
};
</script>
