<template>
  <div class="offer-list">
    <v-container fluid grid-list-xl>
      <v-layout row wrap>
        <v-flex lg12>
          <Toolbar :handle-add="addHandler" :title="$t('Offers')" />
          <DataFilter :handle-filter="onSendFilter" :handle-reset="resetFilter">
            <OfferFilterForm :values="filters" ref="filterForm" slot="filter" />
          </DataFilter>
          <br />
          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page.sync="options.itemsPerPage"
            :loading="isLoading"
            :loading-text="$t('Loading...')"
            :options.sync="options"
            :server-items-length="totalItems"
            @update:options="onUpdateOptions"
            class="elevation-1"
            item-key="@id"
            show-select
            v-model="selected"
          >
            <template v-slot:item.customer="{ item }">
                <v-btn plain color="primary dark" @click="openCustomerDialog(resolveCustomer(item['customer']))">
                  {{ getCustomerItem(item['customer'], 'customerNumber') }}
                </v-btn>
            </template>
            <template slot="item.date" slot-scope="{ item }">
              {{ formatDateTime(item['date'], 'L') }}
            </template>
            <template slot="item.generated" slot-scope="{ item }">
              <v-icon small>
                <template v-if="item.frozen">mdi-check</template>
                <template v-else>mdi-close</template>
              </v-icon>
            </template>
            <template slot="item.confirmed" slot-scope="{ item }">
              <v-icon small>
                <template v-if="item.confirmed">mdi-check</template>
                <template v-else-if="item.confirmed === null && item.frozen === true">mdi-timer-sand</template>
                <template v-else-if="item.confirmed === null && item.frozen === false">mdi-minus</template>
                <template v-else>mdi-cancel</template>
              </v-icon>
            </template>
            <div slot="item.action" slot-scope="props">
              <v-row justify="end">
                <v-icon @click="editHandler(props.item)" small v-if="!props.item.frozen" class="mr-2"
                  >mdi-pencil
                </v-icon>
                <v-icon @click="handleDelete(props.item)" small v-if="!props.item.frozen" class="mr-1"
                  >mdi-delete
                </v-icon>
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="downloadOfferDocument(props.item)">
                      <v-list-item-title>Angebot herunterladen</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="downloadOfferConfirmDocument(props.item)"
                      v-if="isOfferConfirmable(props.item)"
                    >
                      <v-list-item-title>
                        {{ $t('ConfirmOfferConfirm') }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="offerDeclineConfirm(props.item)" v-if="isOfferConfirmable(props.item)">
                      <v-list-item-title>
                        {{ $t('ConfirmOfferDecline') }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="createInvoice(props.item)"
                      v-if="props.item.frozen && props.item.confirmed && !props.item.invoice"
                    >
                      <v-list-item-title>{{ $t('CreateInvoice') }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-row>
            </div>
          </v-data-table>
          <CustomerInfoDialog
            v-model="showCustomerDialog"
            :customer="customerDialogItem"
            :key="customerDialogItem.id"
            @close="showCustomerDialog = false"
          />
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import ListMixin from '../../mixins/ListMixin';
import OfferFilterForm from '../../components/offer/Filter';
import DataFilter from '../../components/DataFilter';
import Toolbar from '../../components/Toolbar';
import ProfileMixin from '@/mixins/ProfileMixin';
import DocumentMixin from '@/mixins/DocumentMixin';
import CustomerMixin from '@/mixins/CustomerMixin';
import OfferMixin from '@/mixins/OfferMixin';
import CustomerInfoDialog from '@/components/customer/CustomerInfoDialog.vue';

export default {
  name: 'OfferList',
  servicePrefix: 'Offer',
  mixins: [ListMixin, ProfileMixin, DocumentMixin, CustomerMixin, OfferMixin],
  components: {
    CustomerInfoDialog,
    Toolbar,
    OfferFilterForm,
    DataFilter
  },
  data() {
    return {
      headers: [
        { text: this.$i18n.t('offerNumber'), value: 'number' },
        { text: this.$i18n.t('customerNumber'), value: 'customer', sortable: false },
        { text: this.$i18n.t('offerDate'), value: 'date' },
        { text: this.$i18n.t('offerGenerated'), value: 'generated', align: 'center', width: '4%', sortable: false },
        { text: this.$i18n.t('offerConfirmed'), value: 'confirmed', align: 'center', width: '4%', sortable: false },
        {
          text: this.$i18n.t('Actions'),
          value: 'action',
          sortable: false,
          align: 'end',
          width: '15%'
        }
      ],
      confirmDeletes: [],
      selected: [],
      filters: {
        customer: {}
      },
      showCustomerDialog: false,
      customerDialogItem: {},
    };
  },
  computed: {
    ...mapGetters('offer', {
      items: 'list'
    }),
    ...mapFields('offer', {
      deletedItem: 'deleted',
      error: 'error',
      isLoading: 'isLoading',
      resetList: 'resetList',
      totalItems: 'totalItems',
      view: 'view',
      handled: 'handled',
      handleAction: 'handleAction'
    })
  },
  methods: {
    ...mapActions('offer', {
      getPage: 'fetchAll',
      deleteItem: 'del',
      actionOffer: 'action'
    }),
    resetFilter() {
      this.filters = {
        customer: {}
      };
    },
    downloadOfferDocument(offer) {
      if (offer.document === null) {
        this.generateOfferDocument(offer);
        return;
      }
      this.getAndDownloadDocument(offer.document);
    },
    downloadOfferConfirmDocument(offer) {
      if (
        typeof offer.confirmationDocument === 'undefined' ||
        offer.confirmationDocument === null ||
        typeof offer.confirmationNumber === 'undefined' ||
        offer.confirmationNumber === null
      ) {
        this.$confirm(this.$t('ConfirmOfferMessage'), {
          title: this.$t('ConfirmOffer'),
          color: 'error',
          buttonTrueText: this.$t('ConfirmOfferOk'),
          buttonFalseText: this.$t('Cancel')
        }).then((response) => {
          if (!response) {
            return;
          }
          this.generateOfferConfirmDocument(offer);
        });
      }
    },
    offerDeclineConfirm(offer) {
      if (offer.confirmed === null) {
        this.$confirm(this.$t('DeclineOfferMessage'), {
          title: this.$t('DeclineOffer'),
          color: 'error',
          buttonTrueText: this.$t('DeclineOfferOk'),
          buttonFalseText: this.$t('Cancel')
        }).then((response) => {
          if (!response) {
            return;
          }
          this.declineOffer(offer);
        });
      }
    },
    isOfferConfirmable(offer) {
      return offer.frozen && offer.confirmed === null;
    },
    handleDelete(offer) {
      this.$confirm(this.$t('Are you sure you want to delete this item?'), {
        buttonTrueText: this.$t('Delete'),
        buttonFalseText: this.$t('Cancel')
      }).then((response) => {
        if (!response) {
          return;
        }
        this.deleteHandler(offer);
      });
    },
    createInvoice(offer) {
      this.$confirm(this.$t('Create an invoice for this offer?'), {
        buttonTrueText: this.$t('Yes'),
        buttonFalseText: this.$t('No')
      }).then((response) => {
        if (!response) {
          return;
        }
        this.actionOffer({ item: offer, action: 'create-invoice' });
      });
    },
    openCustomerDialog(customer) {
      this.customerDialogItem = customer;
      this.showCustomerDialog = true;
    },
  },
  watch: {
    handled() {
      if (this.handleAction === 'confirm') {
        this.getAndDownloadConfirmDocument(this.handled.confirmationDocument);
        this.handleAction = null;
        this.handled = null;
      } else if (this.handleAction === 'generate') {
        this.getAndDownloadDocument(this.handled.document);
        this.handleAction = null;
        this.handled = null;
      } else if (this.handleAction === 'create-invoice') {
        this.showMessage(this.$t('InvoiceCreated'));
        this.$router.push({
          name: 'InvoiceUpdate',
          params: { id: this.handled['@id'] }
        });
        this.handleAction = null;
        this.handled = null;
      }
    }
  },
  created() {
    this.options.sortBy = ['number'];
    this.options.sortDesc = [true];
  }
};
</script>
